import { createStore } from "vuex";
import words from "./words.store";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    words,
  },
});
