<template>
  <div class="card">
    <form>
      <div class="form">
        <label for="word-name">
          Word
        </label>
        <input id="word-name" type="text">
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "WordComponent"
}
</script>

<style scoped>

</style>