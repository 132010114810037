<template>
  <word-component></word-component>
</template>

<script>
import WordComponent from "../components/WordComponent";
export default {
  name: "WordContainer",
  components: { WordComponent },
}
</script>

<style scoped>

</style>