<template>
  <div class="home">
    <WordContainer></WordContainer>
  </div>
</template>

<script>
// @ is an alias to /src
import WordContainer from "@/containers/WordContainer";

export default {
  name: "Home",
  components: {
    WordContainer,
  },
};
</script>
